<template>

	<div v-if="is_show" :style="!hide_settings ? 'flex: 0 0 270px;' : 'flex: 0 0 10px;'">

		<div class="h-100 border-end position-relative preview-settings" style="z-index: 1">

			<button class="btn bg-light d-inline-flex align-items-center position-absolute p-1 border border-start-0 rounded-start-0" v-on:click="[hide_settings = ( hide_settings ? false : true )]" style="top: 12px; right: -33px; z-index: 2;">
				<span class="material-icons-outlined">{{ hide_settings ? 'chevron_right' : 'chevron_left' }}</span>
			</button>
			
			<div v-if="!hide_settings" class="w-100 h-100 overflow-auto p-2">
				
				<div v-if="mode == 'wireframe'">

					<form v-if="cur_page && cur_page.original_content" @submit.prevent="generate_wireframe()" class="position-relative">

						<span class="badge bg-info position-absolute top-0 start-0 ms-3 " style="margin-top: -7px; z-index: 2;">Beta</span>

						<btn_submit 
							label="Generate wireframe" 
							icon="auto_fix_high"  
							:icon_after="true" 
							:loading="generating" 
						/>

						<div class="alert alert-info position-relative mt-2 p-2">

							<a data-bs-toggle="collapse" href="#generate-wireframe-info" class="fw-bold d-flex align-items-center" style="text-decoration: none;">
								<span class="material-icons-outlined me-2">info</span>
								Learn more
							</a>
							<div id="generate-wireframe-info" class="collapse mt-4">
								<p class="mb-0 small">The "Generate wireframe" function will take the content displayed below and format it in the best way then allocate it to the most relevant blocks. Essentially creating your wireframe layout for you and populating it with content.
									<br><br>
								Running this function will overwrite any content you've already added to your wireframe.</p>
							</div>
						</div>
							
					</form>
					
					<div class="card small">
						<ul class="nav nav-tabs nav-fill px-2 pt-2">
							<li class="nav-item">
								<button v-on:click="content_tab = 'content'" class="nav-link" :class="content_tab == 'content' ? 'active bg-white' : ''"  :style="content_tab == 'content' ? 'border-bottom-color: #ffff' : ''">Content</button>
							</li>
							<li class="nav-item">
								<button v-on:click="content_tab = 'features'" class="nav-link" :class="content_tab == 'features' ? 'active bg-white' : ''"  :style="content_tab == 'features' ? 'border-bottom-color: #ffff' : ''">Features</button>
							</li>
						</ul>
						<div class="card-body p-4">
							
							<div v-if="content_tab == 'content'">

								<p class="fw-bold mb-2">Content to include on page:</p>
								<div v-if="cur_page && cur_page.original_content" v-html="cur_page.original_content"></div>
								<p v-else class="mb-0">No content has been added yet for this page. Ask your account manager to provide content for you.</p>
							</div>

							<div v-if="content_tab == 'features'">
								<p class="fw-bold mb-2">Features to include on page:</p>
								
								<p v-if="!cur_page || !cur_page.features.length">No features have been specified yet for this page. Confirm with your account manager if any of these features need to be included.</p>

								<div>
									<div class="list-group">
										
										<div v-for="( feature ) in features" class="list-group-item d-flex align-items-center" :key="'feature-' + feature.key">
											<span class="material-icons-outlined me-2" :class="cur_page.features.includes( feature.key ) ? 'text-success' : 'text-danger'">
												{{ cur_page.features.includes( feature.key ) ? 'check_circle' : 'cancel' }}
											</span>
											{{ feature.name }}
										</div>

									</div>
								</div>
								
							</div>
						</div>
					</div>

				</div>

				<div v-if="!mode || mode == 'prototype'">
					
					<!-- <button 
					v-if="show_tour" 
					v-on:click="$emit('update:showTour', true)" 
					class="btn btn-outline-primary w-100 mb-4 d-flex justify-content-between align-items-center"
					>
						Take tour
						<span class="material-icons-outlined">tour</span>
					</button> -->

					<div class="btn-group mb-2 w-100 border-bottom">
						
						<button
						v-for="( page, page_key ) in top_level_pages" 
						v-on:click="toggle_top_level_active(page)"
						class="btn border-0 border-bottom border-2 rounded-0"
						:class="[top_level_active == page.key ? 'border-primary' : 'border-light']"
						:key="'top-level-page-' + page_key"
						>
							{{ page.name }}
						</button>
						
					</div>

					<div class="list-group position-relative">

						<div v-if="loading" class="w-100 h-100 position-absolute top-0 start-0 bg-white bg-opacity-75" style="z-index: 5;"></div>
						
						<div v-if="active_settings != top_level_active" class="list-group-item text-start p-3 d-flex align-items-center justify-content-between position-sticky" style="z-index: 10; top: calc( 41px - 1.5rem )">
							<button class="btn d-flex align-items-center p-0" v-on:click="active_settings = back_settings">
								<span class="material-icons-outlined me-2">west</span>
								Back
							</button>

							<span v-if="loading" class="d-flex align-items-center small">
								<span class="spinner-border spinner-border-sm me-2" role="status">
									<span class="visually-hidden">Loading...</span>
								</span>
								Updating
							</span>
						</div>

						<div v-if="active_settings == 'comments'">
							
							<comments :workspace="workspace" :site="site" :is_build="is_build" v-on:update:previewSize="(e) => { $emit('update:previewSize', e) }" />
							
						</div>

						<div v-if="active_settings == 'project'">
							<div class="list-group-item">
								<form_control 
									type="text" 
									name="name"
									label="Name"
									:include_delay="true"
									group_class="mb-0" 
									v-model="site.name"
									v-on:update:modelValue="submit()"
								/>
							</div>
						</div>

						<div v-if="active_settings == 'client'">
							<div class="list-group-item">
								<form_control 
									type="select" 
									name="industry"
									label="Company industry"
									:options="store_industries"
									group_class="mb-0"
									field_class="form-select" 
									v-model="site.settings['industry']"
									v-on:update:modelValue="submit( 'industry' )"
								/>
							</div>

							<div class="list-group-item">
								<form_control 
									type="text" 
									name="name"
									label="Company Name"
									:include_delay="true"
									group_class="mb-0"
									field_class="form-control" 
									v-model="site.settings['name']"
									v-on:update:modelValue="submit( 'name' )"
								/>
							</div>

							<div class="list-group-item">
								<form_control 
									type="text" 
									name="email"
									label="Company Email"
									:include_delay="true"
									group_class="mb-0"
									field_class="form-control" 
									v-model="site.settings['email']"
									v-on:update:modelValue="submit( 'email' )"
								/>
							</div>

							<div class="list-group-item">
								<form_control 
									type="text" 
									name="phone"
									label="Company Phone"
									:include_delay="true"
									group_class="mb-0"
									field_class="form-control" 
									v-model="site.settings['phone']"
									v-on:update:modelValue="submit( 'phone' )"
								/>
							</div>

							<div class="list-group-item">
								<form_control 
									type="textarea" 
									name="address"
									label="Company Address"
									:include_delay="true"
									group_class="mb-0"
									field_class="form-control" 
									v-model="site.settings['address']"
									v-on:update:modelValue="submit( 'address' )"
								/>
							</div>
						</div>

						<div v-if="active_settings == 'social'">
							<div v-for="(social, social_key ) in socials" :key="'detail-social-' + social_key" class="list-group-item">
								<form_control
									:label="social.name"
									type="text"
									:name="'settings.' + social.key"
									:error="error"
									group_class="mb-0"
									:include_delay="true"
									v-model="site.settings[social.key]"
									v-on:update:modelValue="submit( 'social' )"
								/>
							</div>
						</div>

						<div v-if="active_settings == 'developer'">
							<div class="list-group-item">
								<form_control 
									type="toggle" 
									name="settings.debug"
									label="Debug mode"
									help="If checked, errors will display as you work on your site."
									:include_delay="true"
									group_class="mb-0" 
									v-model="site.settings['debug']"
									v-on:update:modelValue="submit('debug')"
								/>
							</div>
							<div class="list-group-item">
								<form_control 
									type="toggle" 
									name="settings.exclude_wp_blocks"
									label="Exclude WordPress blocks"
									help="If checked, core WordPress blocks will be excluded from the editor."
									:include_delay="true"
									group_class="mb-0" 
									v-model="site.settings['exclude_wp_blocks']"
									v-on:update:modelValue="submit('debug')"
								/>
							</div>
						</div>

						<div v-if="active_settings == 'logos'">
							<div class="list-group-item">
								<form_control
									type="file" 
									name="dark_logo"
									label="Dark Logo"
									placeholder="Upload Image"
									field_class="form-control rounded-0 border-0 bg-transparent" 
									v-model="site.settings['dark-logo']"
									v-on:update:modelValue="submit( 'dark-logo' )"
								/>
								<div v-if="site.settings['dark-logo']" class="card w-100 mb-2">
									<div class="img-bg"><img :src="site.settings['dark-logo']" height="50" style="max-width: 100%;" /></div>
									<button class="btn btn-link text-danger" v-on:click="[ site.site.settings['dark-logo'] = '', submit( 'dark-logo' ) ]">
										Remove image
									</button>
								</div>
							</div>

							<div class="list-group-item">
								<form_control
									type="file" 
									name="light-logo"
									label="Light Logo"
									placeholder="Upload Image"
									field_class="form-control rounded-0 border-0 bg-transparent" 
									v-model="site.settings['light-logo']"
									v-on:update:modelValue="submit( 'light-logo' )"
								/>
								<div v-if="site.settings['light-logo']" class="card w-100 mb-2">
									<div class="img-bg"><img :src="site.settings['light-logo']" height="50" style="max-width: 100%;" /></div>
									<button class="btn btn-link text-danger" v-on:click="[ site.settings['light-logo'] = '', submit( 'light-logo' ) ]">
										Remove image
									</button>
								</div>
							</div>

							<div class="list-group-item">
								<form_control
									type="file" 
									name="favicon"
									label="Favicon"
									placeholder="Upload Image"
									field_class="form-control rounded-0 border-0 bg-transparent" 
									v-model="site.settings['favicon']"
									v-on:update:modelValue="[ override_question = 'favicon', submit() ]"
								/>
								<div v-if="site.settings['favicon']" class="card w-100 mb-2">
									<div class="img-bg"><img :src="site.settings['favicon']" height="50" style="max-width: 100%;" /></div>
									<button class="btn btn-link text-danger" v-on:click="[ site.settings['favicon'] = '', submit( 'favicon' ) ]">
										Remove image
									</button>
								</div>
							</div>
						</div>

						<div v-if="active_settings == 'colors'">

							<!-- <div class="list-group-item">
								<button 
								class="btn btn-outline-info d-flex align-items-center justify-content-between text-start w-100"
								v-on:click="submit( 'generate-color-scheme' )"
								type="button" 
								:disabled="loading ? true : false"
								>
									<span>
										{{ loading ? 'Generating' : 'Generate' }}
									</span>
									<span v-if="!loading" class="material-icons-outlined ms-3">auto_fix_high</span>
									<span v-else class="spinner-border spinner-border-sm d-block ms-3" role="status">
										<span class="visually-hidden">Loading...</span>
									</span>
								</button>
							</div> -->
							
							<div v-for="( color, color_key ) in colors" class="list-group-item p-2" :key="'styles-color-' + color_key">
								<form_control 
									type="color" 
									:name="color.key"
									:label="color.name"
									group_class="h-100 align-items-center p-0"
									field_class="form-control rounded-0 border-0 bg-transparent" 
									v-model="site.settings[color.key]"
									v-on:update:modelValue="[ submit( color.key ) ]"
								/>
							</div>
						</div>

						<div v-if="active_settings == 'fonts'">

							<!-- <div class="list-group-item">
								<button 
								class="btn btn-outline-info d-flex align-items-center justify-content-between w-100 text-start"
								v-on:click="submit( 'generate-fonts' )"
								type="button" 
								:disabled="loading ? true : false"
								>
									<span>
										{{ loading ? 'Generating' : 'Generate' }}
									</span>
									<span v-if="!loading" class="material-icons-outlined ms-3">auto_fix_high</span>
									<span v-else class="spinner-border spinner-border-sm d-block ms-3" role="status">
										<span class="visually-hidden">Loading...</span>
									</span>
								</button>
							</div> -->
							
							<div v-for="( font, font_key ) in fonts" :key="'detail-font-' + font_key" class="list-group-item w-100  overflow-hidden">

								<div class="card w-100 h-100">

									<div v-if="site.settings[font.key]" class="p-3 d-flex align-items-center w-100 overflow-hidden border-bottom">
											
										<div v-if="!generated_fonts.includes( site.settings[font.key]['family']+site.settings[font.key]['weight'] )" class="d-flex align-items-center" style="height: 65px;">
											<div class="spinner-border" role="status">
												<span class="visually-hidden">Loading...</span>
											</div>
										</div>
										
										<iframe v-on:load="generated_fonts.push( site.settings[font.key]['family']+site.settings[font.key]['weight'] )" :src="get_font_preview( site.settings[font.key]['family'], site.settings[font.key]['weight'] )" style="width: 100%; height: 65px;" :class="!generated_fonts.includes( site.settings[font.key]['family']+site.settings[font.key]['weight'] ) ? 'd-none' : ''" />

									</div>
									
									<div class="p-2">
										<form_control
											:label="font.name + ' Font'"
											type="font"
											placeholder="Select font"
											:name="'settings.' + font.key"
											:input_key="font.key"
											group_class="mb-0"
											:is_thin="true"
											:error="error"
											v-model="site.settings[font.key]"
										/>
									</div>

								</div>

							</div>
						</div>

						<div v-if="active_settings == 'font-sizes'">

							<div class="list-group-item" v-for="( size, size_key ) in font_sizes" :key="'font-size-' + size_key">
								<form_control 
									type="select" 
									:name="size.key"
									:label="size.name"
									:options="size.options"
									group_class="mb-0"
									field_class="form-select" 
									v-model="site.settings[size.key]"
									v-on:update:modelValue="submit( 'font-sizes' )"
								/>
							</div>

						</div>

						<div v-if="active_settings == 'button-defaults'">

							<div class="list-group-item" v-for="( btn_default, btn_default_key ) in button_defaults" :key="'button-default-' + btn_default_key">
								<form_control 
									type="select" 
									:name="btn_default.key"
									:label="btn_default.name"
									:options="theme_colors"
									group_class="mb-0"
									field_class="form-select" 
									v-model="site.settings[btn_default.key]"
									v-on:update:modelValue="submit( 'btn-defaults' )"
								/>
							</div>

						</div>

						<div v-if="active_settings == 'layout'">

							<div class="list-group-item" v-for="( layout, layout_key ) in layouts" :key="'layout-' + layout_key">
								<form_control 
									type="select" 
									:name="layout.key"
									:label="layout.name"
									:options="layout.options"
									group_class="mb-0"
									field_class="form-select" 
									v-model="site.settings[layout.key]"
									v-on:update:modelValue="submit( 'layout' )"
								/>
							</div>

						</div>

						<div v-if="active_settings == 'color-mode'">
							
							<button 
							v-for="( color_mode, color_mode_key ) in color_modes" :key="'color_mode-' + color_mode_key"
							class="list-group-item d-flex align-items-start justify-content-between text-start w-100"
							:class="( site.settings['color-mode'] && site.settings['color-mode'] == color_mode.key ? 'bg-success bg-opacity-25' : '' )"
							v-on:click="[ site.settings['color-mode'] = color_mode.key, submit( 'color-mode' ) ]"
							>
								<span>
									{{ color_mode.name }}
									<!-- <br>
									<span class="small text-dark" style="white-space: normal;">{{ color_mode.description }}</span> -->
								</span>
								<span v-if="site.settings['color-mode'] && site.settings['color-mode'] == color_mode.key" class="material-icons-outlined text-success">check</span>
							</button>
						</div>

						<!-- <div v-if="active_settings == 'layout'">
							
							<button 
							v-for="( theme, theme_key ) in themes" :key="'theme-' + theme_key"
							class="list-group-item d-flex align-items-start justify-content-between text-start w-100"
							:class="( site.settings['theme'] && site.settings['theme'] == theme.key ? 'bg-success bg-opacity-25' : '' )"
							v-on:click="[ site.settings['theme'] = theme.key, submit( 'theme' ) ]"
							>
								<span>
									{{ theme.name }}
									<br>
									<span class="small text-dark" style="white-space: normal;">{{ theme.description }}</span>
								</span>
								<span v-if="site.settings['theme'] && site.settings['theme'] == theme.key" class="material-icons-outlined text-success">check</span>
							</button>
						</div> -->

						<div v-if="active_settings == 'settings'">
							<!-- <p class="fw-bold mb-2 v-step-details">Details</p>
							<button 
							v-for="( page, page_key ) in details_pages" 
							:key="'top-level-page-' + page_key" 
							v-on:click="active_settings = page.key" 
							class="list-group-item text-start py-2 px-3 small mb-1 rounded border d-flex align-items-center justify-content-between w-100"
							>
								{{ page.name }}

								<span class="material-icons-outlined">east</span>
							</button> -->
							<!-- <button 
							v-if="is_build" 
							:key="'top-level-page-developer'" 
							v-on:click="active_settings = 'developer'" 
							class="list-group-item text-start py-2 px-3 small mb-1 rounded border d-flex align-items-center justify-content-between w-100"
							>
								Developer Tools

								<span class="material-icons-outlined">east</span>
							</button> -->

							<p class="fw-bold mb-2 v-step-brand">Brand</p>
							<button 
							v-for="( page, page_key ) in styleguide_pages" 
							:key="'top-level-page-' + page_key" 
							v-on:click="active_settings = page.key" 
							class="list-group-item text-start py-2 px-3 small mb-1 rounded border d-flex align-items-center justify-content-between w-100"
							>
								{{ page.name }}

								<span class="material-icons-outlined">east</span>
							</button>

							<p class="fw-bold mb-2 mt-3">Design system</p>
							<button 
							v-for="( page, page_key ) in styles_pages" 
							:key="'top-level-page-' + page_key" 
							v-on:click="active_settings = page.key" 
							class="list-group-item text-start py-2 px-3 small mb-1 rounded border d-flex align-items-center justify-content-between w-100"
							:class="'v-step-' + page_key"
							>
								{{ page.name }}

								<span class="material-icons-outlined">east</span>
							</button>
						</div>

						<div v-if="active_settings == 'combos'">
							<button 
							v-for="( combo, combo_key ) in combos" :key="'combo-' + combo_key"
							class="list-group-item d-flex align-items-start justify-content-between text-start w-100"
							:class="( site.settings['combo'] && site.settings['combo'] == combo.key ? 'bg-success bg-opacity-25' : '' )"
							v-on:click="[ site.settings['combo'] = combo.key, submit( 'combo' ) ]"
							>
								<span>
									{{ combo.name }}
									<br>
									<span class="small text-dark" style="white-space: normal;">{{ combo.description }}</span>
								</span>
								<span v-if="site.settings['combo'] && site.settings['combo'] == combo.key" class="material-icons-outlined text-success">check</span>
							</button>
						</div>

						<div v-if="active_settings == 'style'">
							<button 
							v-for="( style, style_key ) in theme_styles" :key="'style-' + style_key"
							class="list-group-item d-flex align-items-start justify-content-between text-start w-100"
							:class="( site.settings['style'] && site.settings['style'] == style.key ? 'bg-success bg-opacity-25' : '' )"
							v-on:click="[ site.settings['style'] = style.key, submit( 'style' ) ]"
							>
								<span>
									{{ style.name }}
									<br>
									<span class="small text-dark" style="white-space: normal;">{{ style.description }}</span>
								</span>
								<span v-if="site.settings['style'] && site.settings['style'] == style.key" class="material-icons-outlined text-success">check</span>
							</button>
						</div>

						<div v-if="active_settings == 'alignment'">
							<button 
							v-for="( alignment, alignment_key ) in theme_alignment" :key="'alignment-' + alignment_key"
							class="list-group-item d-flex align-items-start justify-content-between text-start w-100"
							:class="( site.settings['alternate-alignment'] && site.settings['alternate-alignment'] == alignment.key ? 'bg-success bg-opacity-25' : '' )"
							v-on:click="[ site.settings['alternate-alignment'] = alignment.key, submit( 'alternate_alignment' ) ]"
							>
								<span>
									{{ alignment.name }}
									<br>
									<span class="small text-dark" style="white-space: normal;">{{ alignment.description }}</span>
								</span>
								<span v-if="site.settings['alternate-alignment'] && site.settings['alternate-alignment'] == alignment.key" class="material-icons-outlined text-success">check</span>
							</button>
						</div>

						<div v-if="active_settings == 'divider'">
							<button 
							v-for="( divider, divider_key ) in dividers" :key="'divider-' + divider_key"
							class="list-group-item d-flex align-items-start justify-content-between text-start w-100"
							:class="( site.settings['divider'] && site.settings['divider'] == divider.key ? 'bg-success bg-opacity-25' : '' )"
							v-on:click="[ site.settings['divider'] = divider.key, submit( 'divider' ) ]"
							>
								<span>
									{{ divider.name }}
									<br>
									<span class="small text-dark" style="white-space: normal;">{{ divider.description }}</span>
								</span>
								<span v-if="site.settings['divider'] && site.settings['divider'] == divider.key" class="material-icons-outlined text-success">check</span>
							</button>
						</div>

						<div v-if="active_settings == 'transitions'">
							<button 
							v-for="( transition, transition_key ) in transitions" :key="'transition-' + transition_key"
							class="list-group-item d-flex align-items-start justify-content-between text-start w-100"
							:class="( site.settings['transitions'] == transition.key ? 'bg-success bg-opacity-25' : '' )"
							v-on:click="[ site.settings['transitions'] = transition.key, submit( 'transitions' ) ]"
							>
								<span>
									{{ transition.name }}
									<br>
									<span class="small text-dark" style="white-space: normal;">{{ transition.description }}</span>
								</span>
								<span v-if="site.settings['transitions'] == transition.key" class="material-icons-outlined text-success">check</span>
							</button>
						</div>

						<div v-if="active_settings == 'hovers'">
							<button 
							v-for="( hover, hover_key ) in hovers" :key="'hover-' + hover_key"
							class="list-group-item d-flex align-items-start justify-content-between text-start w-100"
							:class="( site.settings['hovers'] == hover.key ? 'bg-success bg-opacity-25' : '' )"
							v-on:click="[ site.settings['hovers'] = hover.key, submit( 'hovers' ) ]"
							>
								<span>
									{{ hover.name }}
									<br>
									<span class="small text-dark" style="white-space: normal;">{{ hover.description }}</span>
								</span>
								<span v-if="site.settings['hovers'] == hover.key" class="material-icons-outlined text-success">check</span>
							</button>
						</div>

						<div v-if="active_settings == 'parallax'">
							<div class="list-group-item">
								
								<form_control
									type="checkbox" 
									name="include_pattern"
									label="Include pattern"
									group_class="mb-0"
									field_class="form-control rounded-0 border-0 bg-transparent" 
									v-model="site.settings['include_pattern']"
									v-on:update:modelValue="[ submit( 'parallax' ) ]"
								/>

								<div class="mt-2" v-if="site.settings['include_pattern']">
									<form_control
										type="file" 
										name="pattern"
										label="Pattern Image"
										placeholder="Upload Image"
										field_class="form-control rounded-0 border-0 bg-transparent" 
										v-model="site.settings['pattern']"
										v-on:update:modelValue="[ submit( 'parallax' ) ]"
									/>
									<div v-if="site.settings['pattern']" class="card w-100 mb-0">
										<div class="img-bg"><img :src="site.settings['pattern']" height="50" style="max-width: 100%;" /></div>
										<button class="btn btn-link text-danger" v-on:click="[ site.settings['pattern'] = '', submit( 'parallax' ) ]">
											Remove image
										</button>
									</div>
								</div>
							</div>

							<div v-for="(index) in [1,2,3]" class="list-group-item" :key="'parallax-shape-' + index">
								
								<form_control
									type="checkbox" 
									:name="'include_shape_' + index"
									:label="'Include Shape ' + index"
									group_class="mb-0"
									field_class="form-control rounded-0 border-0 bg-transparent" 
									v-model="site.settings['include_shape_' + index]"
									v-on:update:modelValue="[ submit( 'parallax' ) ]"
								/>
								<div class="mt-2" v-if="site.settings['include_shape_' + index]">
									<form_control
										type="file" 
										:name="'Shape ' + index"
										:label="'Shape ' + index + ' Image'"
										placeholder="Upload Image"
										field_class="form-control rounded-0 border-0 bg-transparent" 
										v-model="site.settings['shape_' + index]"
										v-on:update:modelValue="[ submit( 'parallax' ) ]"
									/>
									<div v-if="site.settings['shape_' + index]" class="card w-100 mb-0">
										<div class="img-bg"><img :src="site.settings['shape_' + index]" height="50" style="max-width: 100%;" /></div>
										<button class="btn btn-link text-danger" v-on:click="[ site.settings['shape_' + index] = '', submit( 'parallax' ) ]">
											Remove image
										</button>
									</div>
								</div>
							</div>
						</div>

						<div v-if="active_settings == 'parallax-position'">
							<button 
							v-for="( parallax, parallax_key ) in parallaxs" :key="'parallax-' + parallax_key"
							class="list-group-item d-flex align-items-start justify-content-between text-start w-100"
							:class="( site.settings['parallax-position'] && site.settings['parallax-position'] == parallax.key ? 'bg-success bg-opacity-25' : '' )"
							v-on:click="[ site.settings['parallax-position'] = parallax.key, submit( 'parallax' ) ]"
							>
								<span>
									{{ parallax.name }}
									<br>
									<span class="small text-dark" style="white-space: normal;">{{ parallax.description }}</span>
								</span>
								<span v-if="site.settings['parallax-position'] && site.settings['parallax-position'] == parallax.key" class="material-icons-outlined text-success">check</span>
							</button>
						</div>

					</div>

				</div>

			</div>

		</div>

		<font_modal v-if="active_settings == 'fonts'" label="heading" key="heading" v-model="site.settings['heading']" v-on:update:modelValue="[ submit( 'fonts' ) ]" />

		<font_modal v-if="active_settings == 'fonts'" label="heading_2" key="heading_2" v-model="site.settings['heading_2']" v-on:update:modelValue="[ submit( 'fonts' ) ]" />

		<font_modal v-if="active_settings == 'fonts'" label="heading_3" key="heading_3" v-model="site.settings['heading_3']" v-on:update:modelValue="[ submit( 'fonts' ) ]" />

		<font_modal v-if="active_settings == 'fonts'" label="heading_4" key="heading_4" v-model="site.settings['heading_4']" v-on:update:modelValue="[ submit( 'fonts' ) ]" />

		<font_modal v-if="active_settings == 'fonts'" label="heading_5" key="heading_5" v-model="site.settings['heading_5']" v-on:update:modelValue="[ submit( 'fonts' ) ]" />

		<font_modal v-if="active_settings == 'fonts'" label="heading_6" key="heading_6" v-model="site.settings['heading_6']" v-on:update:modelValue="[ submit( 'fonts' ) ]" />

		<font_modal v-if="active_settings == 'fonts'" label="body" key="body" v-model="site.settings['body']" v-on:update:modelValue="[ submit( 'fonts' ) ]" />

	</div>

</template>

<script>
import { mapGetters } from 'vuex';
import form_control from '@/components/form_control'
import font_modal from '@/components/font_modal'
import btn_submit from '@/components/btn_submit'
import comments from '@/components/comments'
import site_service from '@/services/site_service'
import page_service from '@/services/page_service'

export default {
	name: 'components.preview_settings',

	props: {
		modelValue: [Object],
		workspace: [Object],
		is_show: Boolean,
		is_design: Boolean,
		is_build: Boolean,
		mode: String,
		cur_page: Object,
		show_tour: Boolean
	},

	emits: [
		'update:modelValue',
		'update:showTour',
		'update:previewSize',
	],

	data() {
		return {
			site: null,
			error: {},
			loading: false,
			generating: false,
			hide_settings: false,
			active_settings: 'settings',
			top_level_active: 'settings',
			content_tab: 'content',
			theme_colors: [
				{
					value: '',
					name: 'Default'	
				},
				{
					value: 'primary',
					name: 'Primary'	
				},
				{
					value: 'secondary',
					name: 'Secondary'	
				},
				{
					value: 'dark',
					name: 'Dark'	
				},
				{
					value: 'light',
					name: 'Light'	
				},
				{
					value: 'info',
					name: 'Info'	
				},
				{
					value: 'success',
					name: 'Success'	
				},
				{
					value: 'warning',
					name: 'Warning'	
				},
				{
					value: 'danger',
					name: 'Danger'	
				},
				{
					value: 'white',
					name: 'White'	
				},
			],
			top_level_pages: [
				{
					name: 'Comments',
					key: 'comments'
				},
				{
					name: 'Settings',
					key: 'settings'
				},
			],
			details_pages: [
				{
					name: 'Project',
					key: 'project'
				},
				{
					name: 'Client',
					key: 'client'
				},
				{
					name: 'Social',
					key: 'social'
				}
			],
			styleguide_pages: [
				{
					name: 'Logos',
					key: 'logos'
				},
				{
					name: 'Colours',
					key: 'colors'
				},
				
				{
					name: 'Fonts',
					key: 'fonts'
				},
				
			],
			styles_pages: [
				// {
				// 	name: 'Pattern Combos',
				// 	key: 'combos'
				// },
				{
					name: 'Colour Mode',
					key: 'color-mode'
				},
				{
					name: 'Font Sizes',
					key: 'font-sizes'
				},
				{
					name: 'Button Defaults',
					key: 'button-defaults'
				},
				{
					name: 'Layout',
					key: 'layout'
				},
				{
					name: 'Style',
					key: 'style'
				},
				{
					name: 'Alignment',
					key: 'alignment'
				},
				{
					name: 'Divider',
					key: 'divider'
				},
				{
					name: 'Transitions',
					key: 'transitions'
				},
				{
					name: 'Hover Effects',
					key: 'hovers'
				},
				{
					name: 'Parallax',
					key: 'parallax'
				},
				{
					name: 'Parallax Position',
					key: 'parallax-position'
				}
			],
			socials: [
				{
					name: 'Facebook',
					key: 'facebook'
				},
				{
					name: 'Instagram',
					key: 'instagram'
				},
				{
					name: 'LinkedIn',
					key: 'linkedin'
				},
				{
					name: 'Pinterest',
					key: 'pinterest'
				},
				{
					name: 'TikTok',
					key: 'tiktok'
				},
				{
					name: 'Twitter',
					key: 'twitter'
				},
				{
					name: 'YouTube',
					key: 'youtube'
				},
			],
			themes: [
				{
					key: 'default',
					name: 'Default',
					description: 'Full width layout.',
				},
				{
					key: 'fixed',
					name: 'Fixed',
					description: 'Fixed width and centred.',
				},
				{
					key: 'transition',
					name: 'Transition',
					description: 'Full width with background transition as you scroll down the page.',
				}
			],
			theme_alignment: [
				{
					key: '',
					name: 'Default',
					description: 'Manually set the alignment on each block.',
				},
				{
					key: 'true',
					name: 'Alternate',
					description: 'Automatically alternate the alignment of each block.',
				},
			],
			theme_styles: [
				{
					key: 'default',
					name: 'Default',
					description: '',
				},
				{
					key: 'aaio-style-squared',
					name: 'Squared',
					description: '',
				},
				{
					key: 'aaio-style-rounded',
					name: 'Rounded',
					description: '',
				},
				{
					key: 'aaio-style-angled',
					name: 'Angled',
					description: '',
				},
				{
					key: 'aaio-style-soft-shadow',
					name: 'Soft Shadow',
					description: '',
				},
				{
					key: 'aaio-style-hard-shadow',
					name: 'Hard Shadow',
					description: '',
				},
			],
			color_modes: [
				{
					name: 'Alternate',
					key: 'aaio-mode-alternate',
					description: 'Your blocks will alternate between the colours in your palette.'
				},
				{
					name: 'Alternate Dark',
					key: 'aaio-mode-alternate-dark',
					description: 'Your blocks will alternate between darker shades within your palette.'
				},
				{
					name: 'Alternate Light',
					key: 'aaio-mode-alternate-light',
					description: 'Your blocks will alternate between lighter shades within your palette.'
				},
				{
					name: 'White',
					key: 'aaio-mode-white',
					description: 'All blocks will use the white colour as the background.'
				},
				{
					name: 'Primary',
					key: 'aaio-mode-primary',
					description: 'All blocks will use the primary colour as the background.'
				},
				{
					name: 'Secondary',
					key: 'aaio-mode-secondary',
					description: 'All blocks will use the secondary colour as the background.'
				},
				{
					name: 'Dark',
					key: 'aaio-mode-dark',
					description: 'All blocks will use the dark colour as the background.'
				},
				{
					name: 'Light',
					key: 'aaio-mode-light',
					description: 'All blocks will use the light colour as the background.'
				},
				{
					name: 'Success',
					key: 'aaio-mode-success',
					description: 'All blocks will use the success colour as the background.'
				},
				{
					name: 'Warning',
					key: 'aaio-mode-warning',
					description: 'All blocks will use the warning colour as the background.'
				},
				{
					name: 'Danger',
					key: 'aaio-mode-danger',
					description: 'All blocks will use the danger colour as the background.'
				},
				{
					name: 'Info',
					key: 'aaio-mode-info',
					description: 'All blocks will use the info colour as the background.'
				},
			],
			transitions: [
				{
					name: 'None',
					key: '',
					description: ''
				},
				{
					name: 'Fade Up',
					key: 'aaio-transition-fade-up',
					description: ''
				},
				{
					name: 'Fade Left',
					key: 'aaio-transition-fade-left',
					description: ''
				},
				{
					name: 'Fade Right',
					key: 'aaio-transition-fade-right',
					description: ''
				},
				{
					name: 'Fade Word',
					key: 'aaio-transition-fade-word',
					description: ''
				},
				{
					name: 'Fade Word Up',
					key: 'aaio-transition-fade-word-up',
					description: ''
				},
			],
			combos: [
				{
					name: 'Pattern Combo 1',
					key: '1',
					description: ''
				},
				{
					name: 'Pattern Combo 2',
					key: '2',
					description: ''
				},
				{
					name: 'Pattern Combo 3',
					key: '3',
					description: ''
				},
				{
					name: 'Pattern Combo 4',
					key: '4',
					description: ''
				},
				{
					name: 'Pattern Combo 5',
					key: '5',
					description: ''
				},
				{
					name: 'Pattern Combo 6',
					key: '6',
					description: ''
				},
			],
			hovers: [
				{
					name: 'Fade',
					key: 'aaio-hover-fade',
					description: ''
				},
				{
					name: 'Swipe Left',
					key: 'aaio-hover-swipe-left',
					description: ''
				},
				{
					name: 'Swipe Up',
					key: 'aaio-hover-swipe-up',
					description: ''
				}
			],
			blocks: [
				{
					name: 'Accordion',
					key: 'accordion',
					templates: 3
				},
				{
					name: 'Carousel',
					key: 'carousel',
					templates: 11
				},
				{
					name: 'Contact',
					key: 'contact',
					templates: 4
				},
				{
					name: 'Content',
					key: 'content',
					templates: 18
				},
				{
					name: 'CTA',
					key: 'cta',
					templates: 6
				},
				{
					name: 'Footer',
					key: 'footer',
					templates: 3
				},
				{
					name: 'Form',
					key: 'form',
					templates: 9
				},
				{
					name: 'Header',
					key: 'header',
					templates: 6
				},
				{
					name: 'Hero',
					key: 'hero',
					templates: 17
				},
				{
					name: 'Items',
					key: 'items',
					templates: 23
				},
				{
					name: 'Logos',
					key: 'logos',
					templates: 3
				},
				{
					name: 'Media',
					key: 'media',
					templates: 4
				},
				{
					name: 'People',
					key: 'people',
					templates: 12
				},
				{
					name: 'Posts Carousel',
					key: 'posts-carousel',
					templates: 6
				},
				{
					name: 'Posts Grid',
					key: 'posts-grid',
					templates: 8
				},
				{
					name: 'Tabs',
					key: 'tabs',
					templates: 3
				},
			],
			parallaxs: [
				{
					name: 'Position 1',
					key: 'aaio-parallax-1',
					description: ''
				},
				{
					name: 'Position 2',
					key: 'aaio-parallax-2',
					description: ''
				},
			],
			colors: [
				{
					name: 'Primary',
					key: 'primary',
					description: ''
				},
				{
					name: 'Secondary',
					key: 'secondary',
					description: ''
				},
				{
					name: 'Dark',
					key: 'dark',
					description: ''
				},
				{
					name: 'Light',
					key: 'light',
					description: ''
				},
				{
					name: 'Success',
					key: 'success',
					description: ''
				},
				{
					name: 'Warning',
					key: 'warning',
					description: ''
				},
				{
					name: 'Danger',
					key: 'danger',
					description: ''
				},
				{
					name: 'Info',
					key: 'info',
					description: ''
				},
			],
			layouts: [
				{
					name: 'Padding Top',
					key: 'padding_top',
					description: '',
					options: [
						{
							name: 'Small',
							value: 'aaio-pt-sm'
						},
						{
							name: 'Medium',
							value: 'aaio-pt-md'
						},
						{
							name: 'Large',
							value: 'aaio-pt-lg'
						},
						{
							name: 'Extra Large',
							value: 'aaio-pt-xl'
						}
					]
				},
				{
					name: 'Padding Bottom',
					key: 'padding_bottom',
					description: '',
					options: [
						{
							name: 'Small',
							value: 'aaio-pb-sm'
						},
						{
							name: 'Medium',
							value: 'aaio-pb-md'
						},
						{
							name: 'Large',
							value: 'aaio-pb-lg'
						},
						{
							name: 'Extra Large',
							value: 'aaio-pb-xl'
						}
					]
				},
				{
					name: 'Container Width',
					key: 'block_container',
					description: '',
					options: [
						{
							name: 'Fixed',
							value: 'aaio-container-md'
						},
						{
							name: 'Wide',
							value: 'aaio-container-lg'
						},
						{
							name: 'Full Width',
							value: 'aaio-container-fluid'
						},
					]
				},
				{
					name: 'Text Align',
					key: 'text_align',
					description: '',
					options: [
						{
							name: 'Default',
							value: ''
						},
						{
							name: 'Align Left',
							value: 'aaio-text-align-left'
						},
						{
							name: 'Align Right',
							value: 'aaio-text-align-right'
						},
					]
				},
				{
					name: 'Text Align (Mobile)',
					key: 'text_align_sm',
					description: '',
					options: [
						{
							name: 'Default',
							value: ''
						},
						{
							name: 'Align Left',
							value: 'aaio-text-align-sm-left'
						},
						{
							name: 'Align Right',
							value: 'aaio-text-align-sm-right'
						},
					]
				},
			],
			font_sizes: [
				{
					name: 'Heading 1',
					key: 'fs-heading-1',
					description: '',
					options: [
						{
							name: 'Small',
							value: '2rem'
						},
						{
							name: 'Medium',
							value: '2.5rem'
						},
						{
							name: 'Large',
							value: '3rem'
						},
						{
							name: 'Extra Large',
							value: '3.5rem'
						}
					]
				},
				{
					name: 'Heading 2',
					key: 'fs-heading-2',
					description: '',
					options: [
						{
							name: 'Small',
							value: '1.5rem'
						},
						{
							name: 'Medium',
							value: '2rem'
						},
						{
							name: 'Large',
							value: '2.5rem'
						},
						{
							name: 'Extra Large',
							value: '3rem'
						}
					]
				},
				{
					name: 'Heading 3',
					key: 'fs-heading-3',
					description: '',
					options: [
						{
							name: 'Small',
							value: '1.25rem'
						},
						{
							name: 'Medium',
							value: '1.75rem'
						},
						{
							name: 'Large',
							value: '2.25rem'
						},
						{
							name: 'Extra Large',
							value: '2.75rem'
						}
					]
				},
				{
					name: 'Heading 4',
					key: 'fs-heading-4',
					description: '',
					options: [
						{
							name: 'Small',
							value: '1rem'
						},
						{
							name: 'Medium',
							value: '1.5rem'
						},
						{
							name: 'Large',
							value: '2rem'
						},
						{
							name: 'Extra Large',
							value: '2.5rem'
						}
					]
				},
				{
					name: 'Heading 5',
					key: 'fs-heading-5',
					description: '',
					options: [
						{
							name: 'Small',
							value: '0.75rem'
						},
						{
							name: 'Medium',
							value: '1.25rem'
						},
						{
							name: 'Large',
							value: '1.75rem'
						},
						{
							name: 'Extra Large',
							value: '2.25rem'
						}
					]
				},
				{
					name: 'Heading 6',
					key: 'fs-heading-6',
					description: '',
					options: [
						{
							name: 'Small',
							value: '0.5rem'
						},
						{
							name: 'Medium',
							value: '1rem'
						},
						{
							name: 'Large',
							value: '1.5rem'
						},
						{
							name: 'Extra Large',
							value: '2rem'
						}
					]
				},
				{
					name: 'Lead',
					key: 'fs-lead',
					description: '',
					options: [
						{
							name: 'Small',
							value: '1rem'
						},
						{
							name: 'Medium',
							value: '1.25rem'
						},
						{
							name: 'Large',
							value: '1.5rem'
						},
						{
							name: 'Extra Large',
							value: '1.75rem'
						}
					]
				},
				{
					name: 'Body',
					key: 'fs-body',
					description: '',
					options: [
						{
							name: 'Small',
							value: '0.75rem'
						},
						{
							name: 'Medium',
							value: '1rem'
						},
						{
							name: 'Large',
							value: '1.25rem'
						},
						{
							name: 'Extra Large',
							value: '1.5rem'
						}
					]
				},
			],
			button_defaults: [
				{
					name: 'Primary text colour',
					key: 'btn_primary_color',
					description: '',
					options: []
				},
				{
					name: 'Secondary text colour',
					key: 'btn_secondary_color',
					description: '',
					options: []
				},
				{
					name: 'Dark text colour',
					key: 'btn_dark_color',
					description: '',
					options: []
				},
				{
					name: 'Light text colour',
					key: 'btn_light_color',
					description: '',
					options: []
				},
				{
					name: 'Info text colour',
					key: 'btn_info_color',
					description: '',
					options: []
				},
				{
					name: 'Success text colour',
					key: 'btn_success_color',
					description: '',
					options: []
				},
				{
					name: 'Warning text colour',
					key: 'btn_warning_color',
					description: '',
					options: []
				},
				{
					name: 'Danger text colour',
					key: 'btn_danger_color',
					description: '',
					options: []
				},
				{
					name: 'White text colour',
					key: 'btn_white_color',
					description: '',
					options: []
				},
			],
			dividers: [
				{
					name: 'None',
					key: '',
					description: ''
				},
				{
					name: 'Angle',
					key: 'angle',
					description: ''
				},
				{
					name: 'Angles',
					key: 'angles',
					description: ''
				},
				{
					name: 'Curved',
					key: 'curved',
					description: ''
				},
				{
					name: 'Jagged',
					key: 'jagged',
					description: ''
				},
				{
					name: 'Paint',
					key: 'paint',
					description: ''
				},
				{
					name: 'Rounded',
					key: 'rounded',
					description: ''
				},
				{
					name: 'Wavy',
					key: 'wavy',
					description: ''
				},
				{
					name: 'ZigZag',
					key: 'zigzag',
					description: ''
				},
			],
			fonts: [
				{
					name: 'Heading',
					key: 'heading'	
				},
				{
					name: 'Heading 2',
					key: 'heading_2'	
				},
				{
					name: 'Heading 3',
					key: 'heading_3'	
				},
				{
					name: 'Heading 4',
					key: 'heading_4'	
				},
				{
					name: 'Heading 5',
					key: 'heading_5'	
				},
				{
					name: 'Heading 6',
					key: 'heading_6'	
				},
				{
					name: 'Body',
					key: 'body'
				}
			],
			generated_fonts: [],
			features: [
				{
					key: 'media',
					name: 'Media gallery'
				},
				{
					key: 'cta',
					name: 'Call to action'
				},
				{
					key: 'contact',
					name: 'Contact form'
				},
				{
					key: 'logos',
					name: 'Client / partner logos'
				},
				{
					key: 'posts',
					name: 'Related pages'
				}
			]
		}
	},

	computed: {

		...mapGetters( 'site', ['store_industries'] ),

		back_settings()
		{
			var back = null 

			if ( [ 'project', 'client', 'social' ].includes( this.active_settings ) ) {
				back = 'details'
			}

			if ( [ 'logos', 'colors', 'fonts' ].includes( this.active_settings ) ) {
				back = 'styleguide'
			}

			if ( [ 'combos', 'style', 'alignment', 'divider', 'transitions', 'hovers', 'parallax', 'parallax-position', 'font-sizes', 'color-mode' ].includes( this.active_settings ) ) {
				back = 'styles'
			}

			back = 'settings'

			return back
		}
	},

	components: {
		form_control,
		font_modal,
		btn_submit,
		comments
	},

	mounted() {
		this.site = JSON.parse( JSON.stringify( this.modelValue ) )

		this.set_defaults()

		var $ = window.$

		var vm = this
		$( document ).on( 'click', '.v-step__button:not(.v-step__button-skip)', function() {
			vm.active_settings = 'settings'
			vm.top_level_active = 'settings'
		} );

		$( document ).on( 'click', '.list-group-item', function() {
			if ( window.$( '.v-step__button-skip' ).length ) {
				window.$( '.v-step__button-skip' ).trigger( 'click' );
			}
		} );
	},

	methods: {
		reset_error()
		{
			this.error = {}
		},

		async submit( override = null )
		{
			this.loading = true 

			this.wp_loading = true

			this.reset_error()

			this.site.question = 'build'

			if ( override ) {
				this.site.question = override
			}

			site_service.update( this.$route.params.workspace_id, this.$route.params.site_id, this.site ).then(( response ) => {

				this.site = JSON.parse( JSON.stringify( response.data ) )

				this.set_defaults()

				this.success = 'Updated successfully'

				this.$emit('update:modelValue', this.site)

				if ( !this.wp_submitted ) {
					this.get_wp_login()
				}

				this.version++

			}).catch( ( error ) => {

				this.error = error

			}).finally(() => {

				this.loading = false

			})
		},

		set_defaults()
		{
			if ( !this.site.settings['fs-heading-1'] ) {
				this.site.settings['fs-heading-1'] = '2.5rem';
			}
			if ( !this.site.settings['fs-heading-2'] ) {
				this.site.settings['fs-heading-2'] = '2rem';
			}
			if ( !this.site.settings['fs-heading-3'] ) {
				this.site.settings['fs-heading-3'] = '1.75rem';
			}
			if ( !this.site.settings['fs-heading-4'] ) {
				this.site.settings['fs-heading-4'] = '1.5rem';
			}
			if ( !this.site.settings['fs-heading-5'] ) {
				this.site.settings['fs-heading-5'] = '1.25rem';
			}
			if ( !this.site.settings['fs-heading-6'] ) {
				this.site.settings['fs-heading-6'] = '1rem';
			}
			if ( !this.site.settings['fs-lead'] ) {
				this.site.settings['fs-lead'] = '1.25rem';
			}
			if ( !this.site.settings['fs-body'] ) {
				this.site.settings['fs-body'] = '1rem';
			}
			if ( !this.site.settings['padding_top'] ) {
				this.site.settings['padding_top'] = 'aaio-pt-md';
			}
			if ( !this.site.settings['padding_bottom'] ) {
				this.site.settings['padding_bottom'] = 'aaio-pb-md';
			}
			if ( !this.site.settings['block_container'] ) {
				this.site.settings['block_container'] = 'aaio-container-md';
			}
			if ( !this.site.settings['text_align'] ) {
				this.site.settings['text_align'] = '';
			}
			if ( !this.site.settings['text_align_sm'] ) {
				this.site.settings['text_align_sm'] = '';
			}
			if ( !this.site.settings['btn_primary_color'] ) {
				this.site.settings['btn_primary_color'] = '';
			}
			if ( !this.site.settings['btn_secondary_color'] ) {
				this.site.settings['btn_secondary_color'] = '';
			}
			if ( !this.site.settings['btn_dark_color'] ) {
				this.site.settings['btn_dark_color'] = '';
			}
			if ( !this.site.settings['btn_light_color'] ) {
				this.site.settings['btn_light_color'] = '';
			}
			if ( !this.site.settings['btn_info_color'] ) {
				this.site.settings['btn_info_color'] = '';
			}
			if ( !this.site.settings['btn_success_color'] ) {
				this.site.settings['btn_success_color'] = '';
			}
			if ( !this.site.settings['btn_warning_color'] ) {
				this.site.settings['btn_warning_color'] = '';
			}
			if ( !this.site.settings['btn_danger_color'] ) {
				this.site.settings['btn_danger_color'] = '';
			}
			if ( !this.site.settings['btn_white_color'] ) {
				this.site.settings['btn_white_color'] = '';
			}
		},

		generate_wireframe()
		{
			this.generating = true 

			this.wp_loading = true

			this.reset_error()

			page_service.generate_wireframe( this.$route.params.workspace_id, this.$route.params.site_id, this.cur_page ).then(( response ) => {

				console.log(response.data);
				/*this.site = JSON.parse( JSON.stringify( response.data ) )

				this.success = 'Updated successfully'

				this.$emit('update:modelValue', this.site)

				if ( !this.wp_submitted ) {
					this.get_wp_login()
				}*/

				this.version++

			}).catch( ( error ) => {

				this.error = error

			}).finally(() => {

				this.generating = false

			})
		},

		get_font_preview( family, variant )
		{
			var preview = process.env.VUE_APP_API_BASE_URL + 'api/workspace/' + this.$route.params.workspace_id + '/site/' + this.$route.params.site_id + '/font-preview'

			var query = 'font=' + family + '&weight=' + variant

			preview += '?' + query

			return preview
		},

		toggle_top_level_active( page )
		{
			this.top_level_active = page.key
			this.active_settings = page.key

			if ( window.$( '.v-step__button-skip' ).length ) {
				window.$( '.v-step__button-skip' ).trigger( 'click' );
			}
		}
	}
}
</script>

<style>
.preview-settings .form-select,
.preview-settings .form-control {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}
.preview-settings .list-group-item {
	padding: 0.75rem;
}
</style>
